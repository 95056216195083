import * as React from "react"
import Layout from "../components/layout2023"
import Seo from "../components/seo2023"
import FooterV2 from "../componentsv2/footerv2/footerv2"
import Aiv2 from "../componentsv2/aiv2/aiv2"

const Ai = () => (
  <Layout nav={"hidden"} parentClass={`newtheme2024`}>
    <Seo title="AI" />
    <Aiv2 />
    <FooterV2 version="v2" />
  </Layout>
)
export default Ai
