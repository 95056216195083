import React, { useMemo } from "react"
import HeaderV2 from "../headerv2/headerv2"
import Hero from "../../microcomponents/hero/hero"
import ClientLogos from "../../microcomponents/clientlogos/clientlogos"
import LeftRightImageText from "../../microcomponents/leftrightimagetext/leftrightimagetext"
import Button from "../../microcomponents/button/button"
import { ArrowIcon } from "../../microcomponents/icons"
import TopPredictions from "../../microcomponents/toppredictions/toppredictions"
import ClientResults from "../../microcomponents/clientresults/clientresults"
import Image from "../../components/image-component/image-new"

import HeadSubheadHorizontal from "../../microcomponents/headsubheadhorizontal/headsubheadhorizontal"
import Container from "../../microcomponents/container/container"
export default function Aiv2() {
  return (
    <div>
      <HeaderV2 version="v2" />
      <Hero
        version="v2"
        className="purpleeclipse"
        breadcrumb={[
          { name: "Home", url: "/" },
          {
            name:
              "How <span class='hidedesktop hidetablet hideipad hidemobile'>it Works</span>",
            url: "/how-it-works/",
          },
          {
            name: "Prediction <span class='hidemobile'>Engine</span>",
            url: "/prediction-engine/",
          },
          { name: "Ai", url: null },
        ]}
        subHeading={null}
        heading="<span>Personalized predictions</span> for <br class='hideipad'/> your website."
        description="<p>The prediction engine finds high <br class='hidedesktop hidetablet hideipad hidemobile'/> converting <br class='hideipad hidemobile'/> patterns based on data <br class='hidedesktop hidetablet hideipad hidemobile'/> from our repo of <br class='hideipad hidemobile'/> 206,000 test results. Predictions are based on <br class='hidemobile'/> factors like <br class='hidedesktop hidetablet hideipad hidemobile'/> your industry, customer profile, <br class='hideipad hidemobile'/> and <br class='hidedesktop hidetablet hideipad hidemobile'/> page type.</p>"
        tags={["Clustering", "Scoring", "Personalized Predictions"]}
        heroImage="frame_1171275354.webp"
        sourceFolder="spiralyze2024website"
        cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
      />
      <ClientLogos version="v2 gray-bg" />
      <LeftRightImageText
        mainHeading="Grouping test results into similar <br class='hidedesktop hidemobile'/> patterns."
        subHeading="Clustering"
        sectionDescription="<p>Our <a class='blue' href='/scraper/'>scraper</a> finds over 8,000 new <br class='hidetablet hideipad hidemobile'/> tests every month. Tests are <br class='hidemobile'/> categorized based on the conversion <br class='hidetablet hideipad hidemobile'/> pattern tested.</p><p>For example, we detected this <i>Dual <br class='hidetablet hideipad'/> CTA </i> pattern, where a second <br class='hidedesktop hidemobile'/> CTA is <br class='hidetablet hideipad'/> added to a page, run in 377 tests.</p>"
        imageFolder="how_it_works"
        wrapperClasses=""
        sectionImage="howitworksimage1.webp"
        reverse={true}
        innerClasses="pd-112"
        id="clustering"
        imageComponent={
          <>
            <Image
              sourceFolder={"spiralyze2024website"}
              lazyLoad={"true"}
              fallBackImage={`frame_1171275353.webp`}
              alt={`Clustering Tests`}
              imgName={`frame_1171275353.webp`}
              cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
            />
            <p>
              {" "}
              The scraper found 377 A/B tests with a <i>Dual CTA.</i>
            </p>
          </>
        }
      >
        <Button
          href={`/get-demo/`}
          version={"v2"}
          icon={<ArrowIcon />}
          type={`cta`}
        >
          Get a Demo
        </Button>
      </LeftRightImageText>

      <div className="gray-bg pd-112" id="scoring">
        <Container>
          <HeadSubheadHorizontal
            className="vertical-360"
            subHeading="Scoring"
            btnComponent={
              <div className="hidemobile">
                <Button version={`v2`} icon={<ArrowIcon />} href={`/get-demo/`}>
                  Get a Demo
                </Button>
              </div>
            }
            description={`<p>Results are scored based on their trustworthiness. Tests with more <br class='hidedesktop hidetablet hideipad hidemobile'/> traffic, longer duration, and cleaner patterns are more trustworthy and <br class='hidedesktop hidetablet hideipad hidemobile'/> given higher weightings. Similarly, <br class='hidedesktop hidetablet hideipad hidemobile'/> tests from sites most relevant to yours, based on factors such as industry and customer type, are also given higher weightings.</p>`}
          >
            {`Weighting test 
            data <br class="hidedesktop hidetablet hideipad hide" /> by trust & relevance.`}
          </HeadSubheadHorizontal>
          <div
            className="hidedesktop hidetablet hideipad"
            style={{
              marginTop: "40px",
              width: "fit-content",
            }}
          >
            <Button version={`v2`} icon={<ArrowIcon />} href={`/get-demo/`}>
              Get a Demo
            </Button>
          </div>
        </Container>
        <div className="full-vw-section-wrapper image-continous-slider">
          <Image
            sourceFolder={"spiralyze2024website"}
            lazyLoad={"true"}
            className={`slider-full-image`}
            fallBackImage={`random_slider_2.webp`}
            alt="Scoring Tests"
            imgName={[
              {
                imageName: "random_slider_2.webp",
                minBreakpoint: "1024px",
              },
              {
                imageName: "random_slider_1.webp",
                minBreakpoint: "768px",
              },
              {
                imageName: "random_slider.webp",
                minBreakpoint: "250px",
              },
            ]}
            cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
          />
          <p>
            The 377 <i>Dual CTA</i> test results are weighted{" "}
            <br className="hidedesktop hidetablet hideipad" /> based on traffic,
            duration, and
            <br className="hidemobile" /> a number of other trustworthiness
            factors.
          </p>
        </div>
      </div>

      <LeftRightImageText
        mainHeading="Know what tests are <br class='hidedesktop hidetablet hideipad'/> most likely to <br class='hidedesktop hidemobile'/> win big  for you."
        subHeading="Personalized Predictions"
        // sectionDescription="<p>Get predictions for the test patterns <br class='hidetablet hideipad'/> that are most likely to win for <br class='hidedesktop hidemobile'/> you,          their chance of winning, and the size of <br class='hidetablet hideipad hidemobile'/> win.</p><p>Prioritize testing based on data, so <br class='hidetablet hideipad'/> you are always running the <br class='hidedesktop hidemobile'/> best tests.<br/> Continuously updated as new data <br class='hidetablet hideipad hidemobile'/> comes in.</p>"
        sectionDescription="<p>Get predictions for the test patterns <br class='hidetablet hideipad'/> that are most likely to win for <br class='hidedesktop hidemobile'/> your company, their chance of winning, and the <br class='hidetablet hideipad hidemobile'/> size of win.</p><p>Prioritize testing based on  data, so  <br class='hidetablet hideipad'/>you are always running the <br class='hidedesktop hidemobile'/> best tests.<br/> Continuously updated as new data <br class='hidetablet hideipad hidemobile'/> comes in.</p>"
        imageFolder="how_it_works"
        sectionImage="howitworksimage1.webp"
        reverse={true}
        innerClasses="pd-112 reverse"
        wrapperClasses=""
        id="personalizedpredictions"
        imageComponent={
          <>
            <Image
              sourceFolder={"spiralyze2024website"}
              lazyLoad={"true"}
              fallBackImage={`image-in-block_9.webp`}
              alt={`Personalized Predictions`}
              imgName={`image-in-block_9.webp`}
              cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
            />
            <p>
              Predict which tests will win for you and the size of the lift.
            </p>
          </>
        }
      >
        <Button
          href={`/get-demo/`}
          version={"v2"}
          icon={<ArrowIcon />}
          type={`cta`}
        >
          Get a Demo
        </Button>
      </LeftRightImageText>
      <TopPredictions version="v2" />
      <ClientResults version="v2" background="gray-bg" />
    </div>
  )
}
